import { Box, Spinner, Text } from '@chakra-ui/react'
import React from 'react'

function ProcessingPage() {
  return (
    <div>
        <Box h={"100vh"} bg={"#d8d7d7"} color={"#830b03"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <Box boxShadow={"rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;"} bg={"white"} w={"40%"} borderRadius={"10px"} p={"20px"} display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Box display={"flex"} justifyContent={"center"}>
                    <Spinner thickness='4px'
                    speed='0.65s'
                    emptyColor='#d8d7d7'
                    color='#830b03'
                    size='xl' />
                </Box>
                <Box>
                    <Text textAlign={"center"} fontSize={"20px"} fontWeight={700}>Redeem Your product</Text>
                </Box>
                <Box>
                    <Text textAlign={"center"} fontSize={"18px"} fontWeight={400}>Please do not close or refresh your browser</Text>
                </Box>
            </Box>
        </Box>
    </div>
  )
}

export default ProcessingPage